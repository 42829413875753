.beat-mapping-cms {
    background-color: #121212;
    color: white;
    font-family: "Outfit";
    padding: 40px;
    min-height: 100vh;
  }
  
  .cms-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .cms-logo img{
    max-width: 175px;
  }
  
  .cms-header h1 {
    font-size: 32px;
    margin: 0;
  }
  
  .add-song-button {
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .search-bar {
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px;
    background-color: #2a2a2a;
    border: none;
    border-radius: 20px;
    color: white;
    font-size: 16px;
  }
  
  .filters {
    display: flex;
    overflow-x: auto;
    margin-bottom: 20px;
  }
  
  .category-select {
    background-color: #2a2a2a;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 20px;
  }

  .category-select:focus{
    outline: 0;
  }
  
  .filter-button {
    background: none;
    border: none;
    color: #b3b3b3;
    background-color: #282828;
    padding: 10px 15px;
    margin-right: 10px;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 20px;
  }
  
  .filter-button.active {
    background: linear-gradient(180deg, rgba(255,107,107,1) 0%, rgba(251,35,59,1) 100%);
    color: white;
  }

  .sign-out-btn-cms{
    background-color: #121212;
    border: 0;
    color: #fff;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .song-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .song-table th, .song-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #2a2a2a;
  }
  
  .song-table th {
    cursor: pointer;
  }
  
  .song-table tr:hover {
    background-color: #2a2a2a;
  }
  
  .text-green-500 {
    color: #10B981;
  }
  
  .text-yellow-500 {
    color: #F59E0B;
  }
  
  .text-red-500 {
    color: #EF4444;
  }
  
  .edit-button, .delete-button {
    padding: 5px 10px;
    margin-right: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-button {
    background-color: #fff;
    color: #000;
  }
  
  .delete-button {
    background-color: red;
    color: white;
  }

  .submit-btn{
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
    color: #fff;
    border: 0;
    padding: 15px 30px;
    cursor: pointer;
    border-radius: 12px;
    margin-top: 1rem;
    font-weight: 700;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    overflow: scroll;
  }
  
  .modal-content {
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    overflow: scroll;
  }
  
  .modal-content h2 {
    margin-top: 2rem;
    text-align: center;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
  }
  
  .form-input-row{
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 0 auto;
  }
  
  .modal-content input,
  .modal-content select {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #2a2a2a;
    border: none;
    border-radius: 5px;
    color: white;
  }
  
  .youtube-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .video-controls {
    margin-top: 10px;
  }
  
  .video-controls button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .beat-canvas {
    margin-top: 10px;
    border: 1px solid #2a2a2a;
  }
  
  .beat-list {
    margin-top: 20px;
    max-height: 200px;
    overflow-y: auto;
    background-color: #282828;
    padding: 10px;
  }
  
  .beat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .beat-item button {
    padding: 2px 5px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .close-modal {
    margin-top: 20px;
    padding: 10px;
    background-color: #2a2a2a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 20px;
  }

  .beat-counter {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
  }
  
  .beat {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  
  .beat.active {
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
  }

  .no-access{
    height: 100vh;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }

  .no-access h2{
    font-size: 3rem;
  }

  .no-access-button{
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    border-radius: 12px;
  }

  @media(max-width: 768px){
    .beat-mapping-cms {
      width: 100%;
    }

    .edit-button{
      margin-bottom: 1rem;
      width: 100%;
    }

    .delete-button{
      width: 100%;
    }

    .form-input-row{
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 1rem;
    }

    .modal-content input, .modal-content select{
      width: 40%;
    }

    .video-controls{
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: unset;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    .video-controls button{
      width: 100%;
      padding: 15px 30px
    }
  }