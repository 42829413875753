.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  font-weight: 700;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#wave {
  height: 70px;
  width: 70px;
  fill: url(#azucar-gradient);
}

.hidden-svg{
  width:0;
  height:0;
  position:absolute;
}

#Line_1 {
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}

#Line_2 {
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

#Line_3 {
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s;
}

#Line_4 {
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

#Line_5 {
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}

#Line_6 {
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

#Line_7 {
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
  -webkit-animation-delay: 1.05s;
          animation-delay: 1.05s;
}

#Line_8 {
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

#Line_9 {
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
  -webkit-animation-delay: 1.35s;
          animation-delay: 1.35s;
}

@-webkit-keyframes pulse {
  0% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
  50% {
    transform: scaleY(0.7);
    transform-origin: 50% 50%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
}

@keyframes pulse {
  0% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
  50% {
    transform: scaleY(0.7);
    transform-origin: 50% 50%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
}