.account-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: 0.3s ease;
    font-family: "Outfit";
  }
  
  .account-popup-content {
    background-color: #0e0e0e;
    color: #fff;
    text-align: center;
    padding: 40px;
    border-radius: 24px;
    width: 90%;
    max-width: 400px;
    position: relative;
    z-index: 1;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.3s ease;
  }

  .account-popup-bg{
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
  } 

  .account-popup-bg img{
    width: 45%;
  } 
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
  }
  
  .view-plans-button {
    background-color: #e67e22;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
  }
  
  .user-info {
    margin-top: 20px;
  }
  
  .user-info input {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
  }
  
  .email-subscription {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .account-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .account-actions button {
    padding: 10px 20px;
    border: none;
    font-weight: 700;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 12px;
  }

  .support-link{
    margin-top: 1rem;
    text-decoration: none;
    text-align: center;
    color: #fff;
  }

  @media(max-width: 768px){
    .account-popup-content{
      margin: 0 20px;
    }

    .account-popup-bg{
      top: 25%;
    }
    
    .account-popup-bg img{
      width: 100%;
    }
  }