.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 0 20px;
    background-color: #121212;
    background-image: url(/public/landing-page/cta-bg.webp);
    background-size: cover;
    background-position: center;
    font-family: "Outfit";
  }
  
  .auth-form {
    padding: 2rem;
    border-radius: 24px;
    width: 100%;
    max-width: 450px;
    background-color: rgba(19, 19, 19, .74);
    border: 1px solid rgba(255, 255, 255, .15);
  }

  .auth-form-logo{
    width: 40%;
    display: block;
    margin: 0 auto;
  }

  .auth-form h1{
    text-align: center;
    color: #fff;
  }

  .auth-form-inputs{
    display: flex;
    flex-direction: column;
    width: auto;
  }
  
  .auth-title {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .auth-subtitle {
    font-size: 1rem;
    color: #fff;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .auth-input {
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid rgba(255, 255, 255, .3);
    background-color: rgba(255, 255, 255, .15);
    border-radius: 4px;
    font-size: 1rem;
    color: #fff;
  }

  .auth-input:hover{
    outline: #ccc;
  }
  
  .auth-button {
    width: 100%;
    padding: 0.75rem;
    background-image: url('/public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s ease;
    font-weight: 700;
  }
  
  .auth-button:hover {
    transform: scale(1.025);
  }
  
  .auth-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  .auth-form p {
    text-align: center;
    margin-bottom: 1rem;
    color: #fff;
  }
  
  .auth-options {
    margin-top: 1rem;
    text-align: center;
  }
  
  .auth-toggle {
    background: none;
    border: none;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  .auth-toggle:hover {
    color: #ccc;
  }