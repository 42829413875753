body{
  background-color: #121212!important;
}

.rhythm-game {
    display: flex;
    height: 100vh;
    font-family: "Outfit";
    background-color: #121212;
    color: white;
  }
  
  .dashboard, .song-list, .artist-page {
    display: flex;
    width: 100%;
  }
  
  .sidebar {
    width: 240px;
    background-color: #000;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .sidebar-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar-logo{
    width: 65%;
  }

  .sidebar-divider{
    width: 100%;
    height: 1px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    background: rgba(255, 255, 255, 0.1) ;
  }
  
  .sidebar-button-icon{
    width: 20px;
    height: 20px;
  }
  
  .sidebar-top {
    flex-grow: 1;
  }
  
  .sidebar-button {
    display: flex;
    align-items: center;
    gap: .75rem;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background: none;
    border: none;
    color: white;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s ease;
    border-radius: 12px;
  }
  
  .sidebar-button:hover {
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
    border-radius: 12px;
  }

  .sidebar-button:hover .sidebar-button-icon{
    filter: brightness(0) invert(1);
  }
  
  .practice-card {
    background-image: url('../public/landing-page/benefits-gradient-1.webp');
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px;
    transition: .3s ease;
  }
  
  .practice-card h3 {
    margin-top: 0;
  }
  
  .begin-practice-button {
    width: 100%;
    padding: 10px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
    transition: .3s ease;
  }

  .begin-practice-button:hover{
    transform: scale(1.025);
  }
  
  .main-content {
    flex-grow: 1;
    padding: 40px;
    overflow-y: auto;
  }
  
  .main-title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .search-bar {
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px;
    background-color: #2a2a2a;
    border: none;
    border-radius: 20px;
    color: white;
    font-size: 16px;
  }

  .search-input:focus{
    outline: solid 1px #fff;
  }
  
  .dance-styles {
    margin-top: 40px;
  }
  
  .dance-style-cards {
    display: flex;
    gap: 20px;
  }

  .dance-style-card {
    flex: 1;
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: .3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1)
  }

  .dance-style-card:hover{
    transform: scale(1.025);
  }
  
  .gif-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .gif-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-content {
    position: relative;
    z-index: 1;
    padding: 0px 20px 0 20px;
    color: white;
    height: 100%;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
    background: rgba(0, 0, 0, 0.5); /* Add a semi-transparent overlay */
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  
  .card-content h3 {
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 0;
  }
  
  .card-content p {
    font-size: 16px;
  }
  
  /* Hide YouTube logo */
  .ytp-chrome-top, .ytp-show-cards-title {
    display: none !important;
  }  

  .dashboard-title-row{
    display: flex;
    justify-content: space-between;
  }

  .view-all-artists-button{
    background-color: #121212;
    border: 0;
    color: #fff;
    cursor: pointer;
    transition: .3s ease;
  }

  .view-all-artists-button:hover{
    color: rgba(216, 213, 209, .8);
  }
  
  .featured-artists {
    margin-top: 40px;
  }
  
  .artist-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
  }
  
  .artist-card {
    background-color: #181818;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
  }
  
  .artist-card:hover {
    background-color: #282828;
  }
  
  .artist-card img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .artist-card h3 {
    margin: 0;
    font-size: 16px;
  }
  
  .artist-card p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #b3b3b3;
  }
  
  /* Styles for other pages (song list, artist page, game) */
  .song-list, .artist-page, .game-container {
    background-color: #121212;
    color: white;
  }
  
  .back-button {
    background: linear-gradient(180deg, rgba(255,107,107,1) 0%, rgba(251,35,59,1) 100%);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .difficulty-select, .category-select, .count-mode-select {
    background-color: #2a2a2a;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .beat-display {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .beat-indicator {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    margin: 0 5px;
  }

  
  .beat-indicator.active {
    background: #2a2a2a;
  }
  
  .beat-indicator.current {
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
  }
  
  .beat-button, .start-button {
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 700;
    margin-top: 20px;
    transition: .3s ease;
  }

  .beat-button:hover{
    transform: scale(1.025);
  }
  
  /* New styles for filters and song table */
  .filters {
    display: flex;
    overflow-x: auto;
    margin-bottom: 20px;
  }
  
  .filter-button {
    background: none;
    border: none;
    color: #b3b3b3;
    background-color: #282828;
    padding: 10px 15px;
    margin-right: 10px;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 20px;
  }
  
  .filter-button.active {
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
    color: white;
    border-radius: 20px;
  }
  
  .song-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .song-table th, .song-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #2a2a2a;
  }
  
  .song-table th {
    cursor: pointer;
  }
  
  .song-table tr:hover {
    background-color: #2a2a2a;
  }

  button.back-arrow{
    border: 0;
    padding: 0;
    background: transparent;
  }

  .back-arrow {
    width: 24px; /* Adjust as needed */
    height: 24px; /* Adjust as needed */
    cursor: pointer;
    margin-right: 10px;
    vertical-align: middle;
  }
  
  /* If you want to change the color of the SVG, you can use this: */
  .back-arrow {
    filter: invert(1); /* This will make the SVG white, assuming it's originally black */
  }

  .text-green-500 {
    color: #10B981;
  }
  
  .text-yellow-500 {
    color: #F59E0B;
  }
  
  .text-red-500 {
    color: #EF4444;
  }

  .game-container {
    display: flex;
    height: 100vh;
    width: 100%;
  }
  
  .youtube-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin: 0 auto;
  }

  .youtube-player{
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
  }

  .youtube-player iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .game-instructions {
     text-align: center;
     margin-bottom: 15px;
     font-weight: 400;
  }
  
  .start-button {
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 50px;
    transition: .3s ease;
  }

  .start-button:hover{
    transform: scale(1.025);
  }
  
  .game-content {
    background-color: #181818;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .tab-button {
    background: none;
    border: none;
    color: #b3b3b3;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .tab-button.active {
    color: white;
    border-bottom: 2px solid transparent;
    position: relative;
  }

  .tab-button.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-image: url('../public/landing-page/border-gradient.jpg');
    background-size: 100% 2px;
    background-repeat: no-repeat;
  }
  
  .visualize-tab, .test-tab {
    color: white;
  }

  .visualize-tab{
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  .volume-control {
    display: flex;
    align-items: center;
    background-color: #1a1a1a;
    padding: 10px;
    border-radius: 50px;
  }
  
  .volume-control label {
    color: white;
    margin-right: 10px;
    white-space: nowrap;
  }
  
  .volume-control input[type="range"] {
    -webkit-appearance: none;
    width: 150px; /* Adjust this value to match the desired width */
    height: 4px;
    background: #4a4a4a;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 50px;
  }
  
  .volume-control input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background: #fff;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .volume-control input[type="range"]::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: #FB233B;
    cursor: pointer;
    border-radius: 50%;
    border: none;
  }
  
  /* Additional styles for better visibility on dark background */
  .volume-control input[type="range"]::-webkit-slider-runnable-track {
    background: #4a4a4a;
  }
  
  .volume-control input[type="range"]::-moz-range-track {
    background: #4a4a4a;
  }

  .test-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stats {
  margin-top: 20px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.stat-row {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  gap: .5rem;
}

.stat-item {
  text-align: center;
  padding: 10px 20px;
  border-radius: 8px;
  min-width: 120px;
}

.stat-item.correct {
  background: rgba(46, 213, 115, 0.1);
  color: #2ed573;
}

.stat-item.mistakes {
  background: rgba(255, 71, 87, 0.1);
  color: #ff4757;
}

.stat-label {
  font-size: 0.9em;
  opacity: 0.8;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 1.5em;
  font-weight: bold;
}

  .feedback-message {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    background-color: #2a2a2a;
  }

  .streak-meter {
    width: 200px; /* Fixed width, adjust as needed */
    height: 20px;
    background-color: #e0e0e0; /* Light gray background */
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0;
    position: relative;
  }
  
  .streak-meter-fill {
    height: 100%;
    width: 0;
    background-color: #4CAF50; /* Green for correct answers */
    transition: width 0.3s ease, background-color 0.3s ease;
  }
  
  .streak-meter-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #333; /* Dark text for contrast */
    font-weight: bold;
  }
  
  .streak-meter-fill.mistake {
    background-color: #FF0000; /* Red for mistakes */
    transition: background-color 0.1s ease; /* Faster transition for mistake flash */
  }

  .mobile-nav {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    padding: 10px;
    justify-content: space-around;
    z-index: 999;
  }
  
  .mobile-nav button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    color: white;
    font-size: 12px;
  }
  
  .mobile-nav img {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }
  
  .desktop-only {
    display: flex;
  }
  
  .mobile-only {
    display: none;
  }


  .main-search-bar {
    position: relative;
    margin-bottom: 20px;
  }
  
  .main-search-bar .search-input {
    width: 100%;
    padding: 10px;
    background-color: #2a2a2a;
    border: none;
    border-radius: 20px;
    color: white;
    font-size: 16px;
  }

  .main-search-bar .search-input:focus {
    outline: solid 1px #fff;
  }
  
  .main-search-bar .search-results {
    position: absolute;
    top: 100%;
    left: 15px;
    right: 0;
    background-color: #2a2a2a;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
    border-top: #fff solid 1px;
  }
  
  .main-search-bar .result-section {
    padding: 10px;
  }
  
  .main-search-bar .result-section h3 {
    margin: 0 0 10px;
    font-size: 14px;
    color: #b3b3b3;
  }
  
  .main-search-bar .result-item {
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .main-search-bar .result-item:hover {
    background-color: #3a3a3a;
  }
  
  .main-search-bar .no-results {
    padding: 10px;
    color: #b3b3b3;
  }


  .artists-page {
    display: flex;
    height: 100vh;
    background-color: #121212;
    color: white;
    font-family: Arial, sans-serif;
  }
  
  .artists-page .main-content {
    flex-grow: 1;
    padding: 40px;
    overflow-y: auto;
  }
  
  .artists-page .main-title {
    font-size: 32px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .artists-page .back-arrow {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 10px;
    filter: invert(1);
  }
  
  .artists-page .search-bar {
    margin-bottom: 20px;
  }
  
  .artists-page .search-input {
    width: 100%;
    padding: 10px;
    background-color: #2a2a2a;
    border: none;
    border-radius: 20px;
    color: white;
    font-size: 16px;
  }
  
  .artists-page .artist-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
  }
  
  .artists-page .artist-card {
    background-color: #181818;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .artists-page .artist-card:hover {
    background-color: #282828;
  }
  
  .artists-page .artist-card img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .artists-page .artist-card h3 {
    margin: 0;
    font-size: 16px;
  }
  
  .artists-page .artist-card p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #b3b3b3;
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
  
  .beat-button.incorrect {
    animation: shake 0.5s, flash-red 0.5s;
  }
  
  @media (max-width: 768px) {
    .rhythm-game{
      height: 100%;
    }

    .dashboard, .song-list, .artist-page, .game-container{
      flex-wrap: wrap;
      height: 100%;
    }

    .sidebar{
      width: 100%;
      background-color: transparent;
      padding: 20px 20px 0 20px;
    }

    .sidebar-logo{
      width: 45%;
    }

    .side-bar-links{
      display: none;
    }

    .sidebar-bottom{
      display: none;
    }
  
    .mobile-nav {
      display: flex;
    }
  
    .desktop-only {
      display: none;
    }
  
    .mobile-only {
      display: block;
    }
  
    .main-content {
      padding: 20px 20px 70px 20px;
      width: 100%;
    }

    .main-search-bar .search-input{
      width: 94%;
    }

    .search-bar{
      width: 94%;
    }
  
    .dance-style-cards {
      flex-direction: column;
    }

    .dance-style-card{
      margin-bottom: 0;
    }

    .card-content{
      height: 150px;
    }
  
    .artist-grid {
      display: flex;
      overflow-x: auto;
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding-bottom: 20px; /* Add some padding for scrollbar */
    }
  
    .artist-card {
      flex: 0 0 80px; /* Fixed width for mobile scrolling */
      max-width: 80px;
    }
  
    .artist-card:last-child {
      margin-right: 0;
    }

    .practice-card{
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .game-headline{
      font-size: 24px;
    }

    .youtube-container {
      width: 100%;
    }

    .artists-page .main-content{
      padding: 20px 20px 70px;
    }

    .artists-page .search-bar{
      width: 94%;
    }

    .artists-page .artist-grid {
      display: grid;
    }

    .artists-page .artist-card{
      flex: auto;
      max-width: 100%;
    }

    
    .beat-button{
      height: 125px;
      width: 125px;
      border-radius: 50%;
    }
  }
  
  @media (max-width: 480px) {
    .search-input {
      font-size: 14px;
    }

    .artist-card {
      flex: 0 0 80px; /* Slightly smaller for very small screens */
      max-width: 80px;
    }
  
    .main-title {
      font-size: 24px;
    }

    .artists-page .main-title {
      font-size: 24px;
    }

    .artists-page .artist-card img{
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
  }

  