body{
    background: #000000;
}

.home{
    background: #000000;
    font-family: "Outfit";
    font-size: 18px;
    min-height: 100vh;
    overflow: auto;
    color: #fff;
}

.wrapper{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.nav-bar{
    background-color: rgba(0, 0, 0, 0);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 50px;
    padding-top: 10px;
}

.nav-bar-inner{
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 2rem;
    backdrop-filter: blur(20px);
    background-color: rgba(33, 33, 33, .3);
    padding: 10px;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 50px;
}

.logo{
    display: flex;
    align-items: center;
    color: #fff;
    gap: .5rem;
    font-weight: 600;
    max-width: 150px;
}

.logo-img{
    width: 100%;
    height: auto;
}

.nav-link{
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

.nav-actions{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.nav-button{
    padding: 10px 10px;
    background: #fff;
    color: #000;
    border-radius: 50px;
    font-size: 14px;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
}

.hero{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-image: url('../public/landing-page/hero-gradient.webp');
    padding-top: 25px;
    padding-bottom: 50px;
}

.hero-content-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: 75px auto;
    gap: 20px;
}

.hero h1{
    font-weight: 700;
    font-size: 72px;
    text-align: center;
    margin: 0;
}

.hero p{
    font-size: 24px;
    text-align: center;
}


.cta-button{
    padding: 20px 50px;
    background: #fff;
    color: #000;
    border-radius: 50px;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
}

.hero-img-wrapper{
    background-color: rgba(0, 0, 0, .1);
    border-radius: 24px;
    width: 100%;
    padding: 7px;
}

.hero-img{
    width: 100%;
    border-radius: 24px;
}


.problem-container{
    grid-column-gap: 30px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1.15fr 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    display: grid;
    padding: 5rem 0;
}

.problem-content{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.problem-pains{
    background-image:  url('../public/landing-page/problem-gradient.webp');
    background-size: cover;
    height: 80vh;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.pain{
    background: #fff;
    padding: 15px 30px;
    text-align: center;
    color: #000;
    border-radius: 10px;
    font-weight: 500;
}

.section-headline-wrapper{
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.section-title{
    font-size: 48px;
    margin-bottom: 1rem;
}

.section-p{
    width: 100%;
    margin: 0 auto;
}

.benefits{
    padding: 5rem 0;
}

.benefits-container{
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    margin-top: 5rem;
}

.benefits-item{
    width: 47%;
    background-color: #0e0e0e;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 24px;
}

.benefits-image-wrapper{
    overflow: hidden;
    padding: 30px;
    border-radius: 24px;
    margin:  24px;
}

.benefit-1{
    background-image: url('../public/landing-page/benefits-gradient-1.webp');
    background-size: cover;
}

.benefit-2{
    background-image: url('../public/landing-page/benefits-gradient-3.png');
    background-size: cover;
}

.benefit-3{
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
}

.benefit-4{
    background-image: url('../public/landing-page/benefits-gradient-2.webp');
    background-size: cover;
}

.benefits-image-wrapper{
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.benefits-image-wrapper img{
    width: 100%;
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.1)
}

.benefits-content{
    text-align: center;
    padding: 0 24px 24px 24px
}

.benefits-content h3{
    font-size: 32px;
    margin-bottom: 1rem;
}

.testimonials {
    padding: 5rem 0;
  }
  
  .testimonials-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 5rem;
  }
  
  .testimonial-card {
    background-color: rgba(19, 19, 19, .74);
    border: 1px solid rgba(255, 255, 255, .15);
    border-radius: 24px;
    padding: 2rem;
    transition: transform 0.3s ease;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px);
  }
  
  .testimonial-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .testimonial-text {
    font-size: 16px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .testimonial-author {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .testimonial-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .testimonial-info h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
  
  .testimonial-info p {
    margin: 0;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }
  

.pricing-container{
    background-image: url('../public/landing-page/pricing-bg.webp');
    background-size: cover;
    background-position: center;
    border-radius: 24px;
    padding: 48px;
}

  
  .pricing-toggle {
    margin: 2rem auto;
    background-color: rgba(6, 6, 6, .7);
    border-radius: 50px;
    padding: 4px;
    display: flex;
    max-width: 300px;
  }
  
  .toggle-button {
    width: 50%;
    padding: 8px;
    border: none;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: transparent;
    font-family: "Outfit";
    color: #fff;
  }
  
  .toggle-button.active {
    background-color: #fff;
    color: #000;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .save-text {
    color: #00c170;
  }
  
  .pricing-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin-top: 3rem;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  

  .pricing-card {
    border: 1px solid rgba(255, 255, 255, .15);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: rgba(19, 19, 19, .74);
  }
  
  .pricing-card.premium {
    background-color: #fff;
  }
  
  .card-header {
    padding: 24px;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #fff;
  }

  .premium .card-title{
    color: #000;
  }
  
  .card-description {
    margin-top: 1rem;
    font-size: 16px;
    color: rgba(216, 213, 209, .8);
  }

  .premium .card-description{
    color: rgba(0, 0, 0, 0.6);
  }
  
  .price {
    margin-top: 2rem;
  }
  
  .amount {
    font-size: 2.25rem;
    font-weight: 700;
    color: #fff;
  }

  .premium .amount{
    color: #000;
  }
  
  .period {
    font-size: 1rem;
    color: #fff;; 
  }

  .premium .period{
    color: #000;
  }
  
  .annual-note {
    display: block;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 0.5rem;
  }

  .pricing-button-wrapper{
    display: flex;
  }
  
  .card-button {
    margin-top: 2rem;
    text-decoration: none;
    width: 100%;
    padding: 1rem;
    border-radius: 50px;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    font-family: "Outfit";
    transition: 0.2s ease;
  }

  .card-button:hover{
    transform: scale(1.02);
  }
  
  .button-free {
    background-color: #f3f4f6;
    border: 1px solid #e5e7eb;
    color: #374151;
  }
  
  .button-free:hover {
    background-color: #e5e7eb;
  }
  
  .button-premium {
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
    border: 0;
    color: white;
  }
  
  .button-premium:hover {
    background-color: #1d4ed8;
  }
  
  .card-features {
    padding: 1.5rem;
    border-top: 1px solid rgba(255, 255, 255, .1);
  }

  .premium .card-features {
    padding: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .features-title {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
  
  .premium .features-title{
    color: #000;
  }
  
  .features-list {
    margin-top: 1rem;
    list-style: none;
    padding: 0;
  }
  
  .feature-item {
    display: flex;
    align-items: start;
    margin-top: 1rem;
  }
  
  .check-icon {
    width: 20px;
    height: 20px;
    color: #00c170;
    margin-right: 0.75rem;
    flex-shrink: 0;
  }
  
  .feature-item span {
    font-size: 16px;
    color: #fff;;
  }

  .premium .feature-item span{
    color: #000;
  }

.faqs {
    padding: 5rem 0;
}

.faq-bg{
    max-width: 900px;
    padding: 5rem 0;
    background-size: cover;
    height: 100%;
    background-position: top center;
    margin: 0 auto;
    background-image: url('../public/landing-page/blur-gradient.webp');
}


.faq-container {
    margin-top: 5rem;
    max-width: 600px;
    margin:  0 auto 0 auto;
    background-color: #0e0e0e;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 24px;
    border-radius: 24px;
}

.faq-item {
    background-color: #0e0e0e;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    margin-bottom: 1rem;
    overflow: hidden;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    color: #fff;
}

.faq-question h3 {
    margin: 0;
    font-size: 18px;
}

.faq-toggle {
    font-size: 24px;
    font-weight: bold;
    transition: transform 0.3s ease;
}

.instructor-link{
    color: white;
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}

.faq-item.active .faq-answer {
    max-height: 1000px;
    padding: 1rem;
}

.faq-item.active .faq-toggle {
    transform: rotate(45deg);
}

.copy-link-container {
margin-top: 15px;
align-items: center;
display: flex;
flex-direction: column;
text-align: center;
}

.copy-link-input {
display: flex;
align-items: center;
gap: 10px;
}

.copy-link-input input {
padding: 10px;
border: 0;
border-radius: 12px;
border: 1px solid rgba(255, 255, 255, .3);
background-color: rgba(255, 255, 255, .15);
color: #fff;
}

.copy-link-input input:focus{
    outline: 0;
}

.copy-link-input button {
padding: 10px 15px;
background-image: url('../public/landing-page/benefits-gradient-4.png');
background-size: cover;
background-position: center;
border-radius: 12px;
color: white;
border: none;
cursor: pointer;
transition: .3s ease;
font-weight: 700;
}

.copy-link-input button:hover {
opacity: .8;
}

.cta{
    background-image: url(../public/landing-page/cta-bg.webp);
    background-size: cover;
    background-position: center;
    padding: 5rem 0;
}

.cta-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
}

.cta h2{
    margin-bottom: 0rem;
    margin-top: 0;
}

.footer-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
}

.create-link{
    color: #fff;
    text-decoration: none;
}

.footer-links{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
}

.footer-links a{
    color: #fff;
    text-decoration: none;
}

@media(max-width: 1024px){
    .wrapper{
        padding: 0 20px;
    }

    .benefits-item{
        width: 45%
    }

    .testimonials-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width: 768px){
    .nav-bar{
        padding: 10px 20px 0 20px;
        width: 86%;
    }

    .logo{
        width: 35%;
    }
    
    .nav-actions{
        gap: .5rem;
    }

    .nav-link{
        font-size: 16px;
    }

    .nav-button{
        display: none;
    }

    .cta-button{
        font-size: 14px;
    }

    .hero-content-wrapper h1{
        font-size: 40px;
    }

    .hero p{
        font-size: 18px;
        margin: 0 0 20px 0;
    }

    .hero-img-wrapper{
        width: 96.5%;
    }

    .problem-container{
        display: flex;
        flex-direction: column;
    }

    .section-title{
        font-size: 36px;
    }

    .problem-container{
        padding: 3rem 0;
    }
    
    .problem-content{
        width: 100%;
    }

    .problem-pains{
        width: 100%;
        height: 100%;
        padding: 25px 0;
    }

    .benefits{
        padding: 0rem;
    }

    .benefits-container{
        margin-top: 3rem;
    }

    .benefits-item{
        width: 100%
    }

    .benefits-content h3{
        font-size: 24px;
    }

    .benefits-image-wrapper {
        height: 200px;
    }

    .testimonials-grid {
        grid-template-columns: 1fr;
    }

    .pricing-container{
        padding: 24px;
    }

    .pricing-cards{
      grid-template-columns: 1fr;
    }

    .faq-bg{
        padding: 5rem 0 0 0;
    }

    .copy-link-container{
        align-items: stretch;
    }

    .copy-link-input{
        flex-direction: column;
        align-items: stretch;
    }

    .cta{
        padding: 3rem 0;
    }

    .footer-inner{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}


