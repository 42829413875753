.language-selector {
position: relative;
}

.language-button {
background: none;
border: none;
cursor: pointer;
text-transform: uppercase;
color: #fff;
display: flex;
align-items: center;
font-size: 20px;
}


.flag-icon {
width: 50px; /* Adjust size as needed */
height: auto;
border-radius: 2px; /* Optional: adds slight rounding to flag corners */
}

.dropdown-arrow {
font-size: 12px;
color: #fff;
margin-left: 5px;
}

.language-dropdown {
position: absolute;
top: 100%;
right: 0;
background-color: white;
border: 1px solid #ccc;
border-radius: 4px;
list-style-type: none;
padding: 0;
margin: 0;
box-shadow: 0 2px 5px rgba(0,0,0,0.1);
z-index: 1000;
}

.language-dropdown li {
padding: 10px;
cursor: pointer;
display: flex;
align-items: center;
gap: 1rem;
color: #000;
font-size: 16px;
transition: .3s ease;
}

.language-dropdown li:hover {
    background-image: url('../public/landing-page/benefits-gradient-4.png');
    background-size: cover;
    background-position: center;
    color: #fff;
}

.language-dropdown .flag-icon {
margin-right: 8px;
}